// TODO: ESLint rule to tune globally
/* eslint-disable no-useless-constructor, no-empty-function */

import type {
  DefaultApi,
  ErrorResponse,
  UsersProjectsUserIdGet200ResponseOneOf,
  ProjectPost200ResponseOneOf,
  ProjectProjectIdGet200ResponseOneOf,
  ProjectWithUsers,
  BusySharedProjectPost200ResponseOneOf,
} from '../api-client';
import { ResultsToDuplicate } from '../api-client';
import { ForbiddenAccessError, InvalidResponseError, NotFoundError } from '../errors';
import type { ResultType, CurrentUseState } from '../types';
import type { User } from '../user';
import { ParseError, convertIfParseError, safeParseDate, safeParseFloat } from '../util';
import type { ProjectApi, Project, ProjectForm } from './project.interface';

export class ProjectApiFacade implements ProjectApi {
  constructor(
    private apiClient: DefaultApi,
    private currentUser: Promise<User>,
  ) {}

  async createProject(projectData: ProjectForm): Promise<number> {
    const userId = (await this.currentUser).id;
    const response = await this.apiClient.projectPost({
      user_id: userId,
      name: projectData.name,
      description: projectData.description,
      address: projectData.address,
      latitude: projectData.latitude as unknown as string,
      longitude: projectData.longitude as unknown as string,
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }

    const rawNewProject = (response.data as ProjectPost200ResponseOneOf).data!;

    return rawNewProject.id!;
  }

  // eslint-disable-next-line class-methods-use-this
  private convertRawProjectToProject(rawProject: ProjectWithUsers): Project {
    return {
      // TODO: improve API schema: Project fields should not be optional
      id: rawProject.id!,
      name: rawProject.name!,
      description: rawProject.description!,
      savedAt: safeParseDate(rawProject.last_save_time!),
      latitude: safeParseFloat(rawProject.latitude!),
      longitude: safeParseFloat(rawProject.longitude!),
      address: rawProject.address!,
      users: rawProject.users!.map((rawUser) => ({
        id: rawUser.id!,
        firstName: rawUser.first_name!,
        lastName: rawUser.last_name!,
        login: rawUser.login!,
        projectRole: (() => {
          // We assume the profileID/role mapping never changes
          // The API exposes the actual mapping at /share-project/manage-member
          switch (rawUser.profile) {
            case 2:
              return 'OWNER';
            case 3:
              return 'MEMBER';
            default:
              throw new ParseError(`invalid user profile id ${rawUser.profile}`);
          }
        })(),
      })),
    };
  }

  async getUserProjects(): Promise<Project[]> {
    const userId = (await this.currentUser).id;
    const response = await this.apiClient.usersProjectsUserIdGet(userId);
    // TODO: improve API schema: use a better operationId

    if (response.data.status === false) {
      // Our user ID comes from the authentication mechanism, and the authentication has worked
      // at that point. Therefore if p10-backend fails with user ID not found, it actually means
      // there is an authorization issue.
      throw new ForbiddenAccessError();
    }

    const rawProjects = (response.data as UsersProjectsUserIdGet200ResponseOneOf).data!;
    // TODO: improve API schema: shorter name for the success response

    try {
      return rawProjects.map((rawProject) => this.convertRawProjectToProject(rawProject));
    } catch (error) {
      throw convertIfParseError(error);
    }
  }

  async getProjectById(projectId: number): Promise<Project> {
    const response = await this.apiClient.projectProjectIdGet(projectId);
    // TODO: improve API schema: use a better operationId

    if (response.data.status === false) {
      // Our user ID comes from the authentication mechanism, and the authentication has worked
      // at that point. Therefore if p10-backend fails with user ID not found, it actually means
      // there is an authorization issue.
      throw new ForbiddenAccessError();
    }

    const rawProject = (response.data as ProjectProjectIdGet200ResponseOneOf).data!;

    try {
      return this.convertRawProjectToProject(rawProject);
    } catch (error) {
      throw convertIfParseError(error);
    }
  }

  async updateProject(projectId: number, projectData: ProjectForm): Promise<void> {
    const response = await this.apiClient.projectProjectIdUpdatePut(projectId, {
      name: projectData.name,
      description: projectData.description,
      address: projectData.address,
      latitude: projectData.latitude as unknown as string,
      longitude: projectData.longitude as unknown as string,
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }
  }

  async duplicateProject(
    projectIdToDuplicate: number,
    newProjectData: ProjectForm,
    resultsTypesToDuplicate: ResultType,
  ): Promise<number> {
    const userId = (await this.currentUser).id;
    let requestResultsTypesToDuplicate: ResultsToDuplicate =
      ResultsToDuplicate.RightSquareBracket;
    if (resultsTypesToDuplicate === 'DEMANDS')
      requestResultsTypesToDuplicate = ResultsToDuplicate.Demand;
    else if (resultsTypesToDuplicate === 'DEMANDS_AND_CONSUMPTIONS') {
      requestResultsTypesToDuplicate = ResultsToDuplicate.Demandsystems;
    }
    const response = await this.apiClient.projectProjectIdDuplicatePost(projectIdToDuplicate, {
      user_id: userId,
      name: newProjectData.name,
      description: newProjectData.description,
      address: newProjectData.address,
      latitude: newProjectData.latitude as unknown as string,
      longitude: newProjectData.longitude as unknown as string,
      results: requestResultsTypesToDuplicate,
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }

    const rawDuplicatedProject = (response.data as ProjectPost200ResponseOneOf).data!;
    return rawDuplicatedProject.id!;
  }

  async deleteProject(projectId: number): Promise<void> {
    const response = await this.apiClient.projectProjectIdDeleteDelete(projectId);

    if (response.data.status === false) {
      if (
        (response.data as ErrorResponse).message!.includes(
          'one of its studies has a pending simulation',
        )
      )
        throw new ForbiddenAccessError();
      throw new InvalidResponseError();
    }
  }

  async getCurrentUseState(projectId: number): Promise<CurrentUseState> {
    const userId = (await this.currentUser).id;
    const response = await this.apiClient.busySharedProjectPost({
      project_id: projectId,
      user_id: userId,
    });

    if (response.data.status === false) {
      throw new NotFoundError();
    }

    const rawData = (response.data as BusySharedProjectPost200ResponseOneOf).data!;

    return {
      isCurrentlyUsed: rawData.locked!,
      ...(rawData.locked && { usedBy: rawData.user }),
    };
  }
}
