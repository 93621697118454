/* eslint-disable class-methods-use-this */

import type { ClosedRing } from '@/components/soon-shared/util';
import * as turf from '@turf/turf';
import { geoJSONCoordinatesToPolygonGeometry } from '@/util/geography';
import { type ResultType, type CurrentUseState } from '../types';
import { InvalidInputError, ForbiddenAccessError, NotFoundError } from '../errors';
import type { StudyApi, Study, StudyForm, StudyInfo, ImportedBuilding } from './study.interface';
import { checkProjectsAccess } from '../project/project.mock';
import { performanceOptions, usageCodeOptions } from '../thermal-zone/thermal-zone.interface';

const project1Studies: Study[] = [
  {
    id: 1,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS',
    description: '10000 batiments',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'LOCKED',
          url: '/study/1/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/1/results-demands',
        },
        systemsModelling: {
          state: 'LOCKED',
          url: '/study/1/study-systems',
        },
        sizing: {
          state: 'LOCKED',
          url: '/study/1/study-systems',
        },
        resultsConsumptions: {
          state: 'RUNNING',
          url: '/study/1/results-consumptions',
        },
      },
    },
  },
  {
    id: 2,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CALIBRAGE',
    description: '',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/2/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'FUTURE',
          url: '/study/2/results-demands',
        },
        systemsModelling: {
          state: 'FUTURE',
          url: '/study/2/study-systems',
        },
        sizing: {
          state: 'FUTURE',
          url: '/study/2/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/2/results-consumptions',
        },
      },
    },
  },
  {
    id: 3,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS RACCORDEMENT RESEAU',
    description: '',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'LOCKED',
          url: '/study/3/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'RUNNING',
          url: '/study/3/results-demands',
        },
        systemsModelling: {
          state: 'FUTURE',
          url: '/study/3/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/3/results-consumptions',
        },
      },
    },
  },
  {
    id: 4,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CORRIGE APRES POINTAGE',
    description: '',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/4/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/4/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/4/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/4/results-consumptions',
        },
      },
    },
  },
  {
    id: 5,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CORRIGE APRES POINTAGE SANS SUPRESSION',
    description: '',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'LOCKED',
          url: '/study/5/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/5/results-demands',
        },
        systemsModelling: {
          state: 'LOCKED',
          url: '/study/5/study-systems',
        },
        sizing: {
          state: 'RUNNING',
          url: '/study/5/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/5/results-consumptions',
        },
      },
    },
  },
  {
    id: 6,
    projectId: 1,
    name: 'DUPLICAT ETAT INITIAL EURALYS CORRIGE APRES POINTAGE SANS SUPRESSION',
    description: '',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/6/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/6/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/6/study-systems',
        },
        sizing: {
          state: 'SUCCESS',
          url: '/study/6/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/6/results-consumptions',
        },
      },
    },
  },
];

const project2Studies: Study[] = [
  {
    id: 7,
    projectId: 2,
    name: 'TEST VARIANTE UTILISEE PAR UN UTILISATEUR',
    description: 'version de bruz initialisé',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/7/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/7/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/7/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/7/results-consumptions',
        },
      },
    },
  },
  {
    id: 8,
    projectId: 2,
    name: 'Essai2 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/8/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/8/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/8/study-systems',
        },
        resultsConsumptions: {
          state: 'ERROR',
          url: '/study/8/results-consumptions',
        },
      },
    },
  },
  {
    id: 9,
    projectId: 2,
    name: 'Essai4 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/9/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/9/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/9/study-systems',
        },
        resultsConsumptions: {
          state: 'ERROR',
          url: '/study/9/results-consumptions',
        },
      },
    },
  },
  {
    id: 10,
    projectId: 2,
    name: 'Essai5 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'LOCKED',
          url: '/study/10/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'RUNNING',
          url: '/study/10/results-demands',
        },
        systemsModelling: {
          state: 'FUTURE',
          url: '/study/10/study-systems',
        },
        resultsConsumptions: {
          state: 'FUTURE',
          url: '/study/10/results-consumptions',
        },
      },
    },
  },
  {
    id: 11,
    projectId: 2,
    name: 'Essai6 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/11/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/11/results-demands',
        },
        systemsModelling: {
          state: 'ACTION_REQUIRED',
          url: '/study/11/study-systems',
        },
        resultsConsumptions: {
          state: 'CANCELED',
          url: '/study/11/results-consumptions',
        },
      },
    },
  },
];

const project4Studies: Study[] = [
  {
    id: 13,
    projectId: 4,
    name: 'Une seule variante',
    description: '',
    savedAt: new Date(),
    state: {
      statesByStep: {
        buildingModelling: {
          state: 'SUCCESS',
          url: '/study/9/buildings-modelling',
        },
        resultsBuildingModelling: {
          state: 'SUCCESS',
          url: '/study/9/results-demands',
        },
        systemsModelling: {
          state: 'SUCCESS',
          url: '/study/9/study-systems',
        },
        resultsConsumptions: {
          state: 'SUCCESS',
          url: '/study/9/results-consumptions',
        },
      },
    },
  },
];
/*
  Please refer to the project mock identify their rights
*/

const user1studiesIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13];
const otherStudiesIds = [12];

const availableStudy = {
  isCurrentlyUsed: false,
};

const notAvailableStudy = {
  isCurrentlyUsed: true,
  usedBy: 'foo.bar@efficacity.com',
};

const checkStudiesAccess = (studyId: number) => {
  if (![...user1studiesIds, ...otherStudiesIds].includes(studyId)) throw new NotFoundError();
  if (!user1studiesIds.includes(studyId)) throw new ForbiddenAccessError();
};
export class StudyApiMock implements StudyApi {
  async createStudy(projectId: number, studyData: StudyForm): Promise<number> {
    checkProjectsAccess(projectId);
    if (studyData.name.length === 0) throw InvalidInputError;
    return 10;
  }

  async getProjectStudies(projectId: number): Promise<Study[]> {
    checkProjectsAccess(projectId);
    if (projectId === 1) return project1Studies;
    else if (projectId === 2) return project2Studies;
    else if (projectId === 4) return project4Studies;
    else return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async updateStudy(studyId: number, studyData: StudyForm): Promise<number> {
    checkStudiesAccess(studyId);
    return 1;
  }

  async duplicateStudy(
    studyId: number,
    newStudyData: StudyForm,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resultsToDuplicate: ResultType,
  ): Promise<number> {
    checkStudiesAccess(studyId);
    if (newStudyData.name.length === 0) throw new InvalidInputError();
    return 1;
  }

  async deleteStudy(studyId: number): Promise<void> {
    checkStudiesAccess(studyId);
  }

  async getCurrentUseState(studyId: number): Promise<CurrentUseState> {
    if (studyId === 7) return notAvailableStudy;
    return availableStudy;
  }

  getStudyById(studyId: number): Study {
    const foundStudy = [...project1Studies, ...project2Studies, ...project4Studies].find(
      (study) => study.id === studyId,
    );

    if (!foundStudy) throw new Error('study not found within projects');
    return foundStudy;
  }

  async getStudy(studyId: number): Promise<Study> {
    return this.getStudyById(studyId);
  }

  async getStudyInfo(studyId: number): Promise<StudyInfo> {
    const study = this.getStudyById(studyId);

    return {
      id: studyId,
      projectId: study.projectId,
      name: study.name,
      description: study.description,
      savedAt: new Date(),
    };
  }

  async importBuildings(importPerimeter: ClosedRing): Promise<Array<ImportedBuilding>> {
    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });
    const rawPolygons = turf.tesselate(
      turf.polygon([importPerimeter.map(({ lat, lng }) => [lng, lat])]),
    );
    const importedBuildings: Array<ImportedBuilding> = rawPolygons.features.map(
      (feature: any) => ({
        externalId: `BATIMENT0000${Math.floor(Math.random() * (20000 - 10000 + 1)) + 10000}`,
        geometry: geoJSONCoordinatesToPolygonGeometry(
          turf.transformScale(feature.geometry, 0.9).coordinates,
        ),
        properties: {
          altitude: 94,
          floor_count: 2,
          mean_floor_height: 2.7,
          performance: performanceOptions[Math.floor(Math.random() * performanceOptions.length)],
          usage_zone_code: usageCodeOptions[Math.floor(Math.random() * usageCodeOptions.length)],
          year: 2015,
        },
      }),
    );
    return importedBuildings;
  }
}
