import type { LatLngLiteral } from 'leaflet';
import * as turf from '@turf/turf';
import { type PolygonGeometry, toRing } from '@/components/soon-shared/util';
import _ from 'lodash';
import axios, { type AxiosResponse } from 'axios';

export type GetElevationStatus = 'SUCCESS' | 'USED_BACKUP' | 'FAILED';

/**
 * Get an elevation (above the sea level in meters) using open-elevation.
 * Round coordinates to 6 values after decimal, risk of timeout if too much.
 * Open-elevation returns an elevation at sea level if there is no recorded elevation
 */
export async function getElevation(
  coordinates: LatLngLiteral,
): Promise<{ elevation: number; status: GetElevationStatus }> {
  let altitude: number | undefined;
  let ignApiResponse: AxiosResponse | undefined;
  let openElevationApiResponse: AxiosResponse | undefined;

  // Attempt to get elevation from IGN API
  try {
    ignApiResponse = await axios.get('https://wxs.ign.fr/essentiels/alti/rest/elevation.json', {
      params: {
        lat: `${_.round(coordinates.lat, 6)}`,
        lon: `${_.round(coordinates.lng, 6)}`,
        zonly: true,
      },
    });
  } catch (error) {}

  if (ignApiResponse !== undefined) {
    if (ignApiResponse.status === 200 && ignApiResponse.data.elevations) {
      altitude = _.max(ignApiResponse.data.elevations);
      // API return -99999 for areas not covered by database
      if (altitude !== undefined && altitude !== -99999) {
        return {
          elevation: altitude,
          status: 'SUCCESS',
        };
      }
    }
  }

  // If IGN fails, try Open-Elevation API
  try {
    openElevationApiResponse = await axios.get('https://api.open-elevation.com/api/v1/lookup', {
      params: {
        locations: `${_.round(coordinates.lat, 6)},${_.round(coordinates.lng, 6)}`,
      },
    });
  } catch (error) {}

  if (openElevationApiResponse !== undefined) {
    if (openElevationApiResponse.status === 200 && openElevationApiResponse.data.results) {
      const altitudes = openElevationApiResponse.data.results.map(
        (result: { elevation: number }) => result.elevation,
      );
      altitude = _.max(altitudes);

      if (altitude !== undefined) {
        return {
          elevation: altitude,
          status: 'USED_BACKUP',
        };
      }
    }
  }

  // If Open-Elevation fails, return default value
  return {
    elevation: 0,
    status: 'FAILED',
  };
}

export function polygonGeometryToGeoJSONCoordinates(
  vertices: PolygonGeometry,
): Array<Array<[number, number]>> {
  return vertices.map((shape) => shape.map(({ lng, lat }) => [lng, lat]));
}

export function geoJSONCoordinatesToPolygonGeometry(
  coordinates: Array<Array<[number, number]>>,
): PolygonGeometry {
  return coordinates.map((shape: Array<[number, number]>) =>
    toRing(shape.map(([lng, lat]) => ({ lat, lng }))),
  );
}

function toLatLngLiteral(coordinates: [number, number]): LatLngLiteral {
  return {
    lng: coordinates[0],
    lat: coordinates[1],
  };
}

export function getCentroid(vertices: PolygonGeometry): LatLngLiteral {
  return toLatLngLiteral(
    turf.centroid(turf.polygon(polygonGeometryToGeoJSONCoordinates(vertices))).geometry
      .coordinates,
  );
}

export function isOverlapping(polygonA: PolygonGeometry, polygonB: PolygonGeometry): boolean {
  const polygonAGeoJSON = turf.polygon(polygonGeometryToGeoJSONCoordinates(polygonA));
  const polygonBGeoJSON = turf.polygon(polygonGeometryToGeoJSONCoordinates(polygonB));

  const intersection = turf.intersect(
    turf.featureCollection([polygonAGeoJSON, polygonBGeoJSON]),
  );

  if (intersection) {
    const area = Math.round(turf.area(intersection));
    return area > 0;
  }
  return false;
}

export function polygonsIntersect(
  polygons1: Array<PolygonGeometry>,
  polygons2: Array<PolygonGeometry>,
): Array<PolygonGeometry> {
  const intersection: Array<PolygonGeometry> = [];
  polygons1.forEach((polygons1Polygon) => {
    polygons2.forEach((polygons2Polygon) => {
      const intersectionFeature = turf.intersect(
        turf.featureCollection([
          turf.polygon(polygonGeometryToGeoJSONCoordinates(polygons1Polygon)),
          turf.polygon(polygonGeometryToGeoJSONCoordinates(polygons2Polygon)),
        ]),
      );
      if (intersectionFeature !== null) {
        const intersectionCoordinates: Array<Array<[number, number]>> =
          intersectionFeature.geometry.coordinates;
        intersection.push(geoJSONCoordinatesToPolygonGeometry(intersectionCoordinates));
      }
    });
  });
  return intersection;
}

export function getArea(geometry: PolygonGeometry): number {
  return turf.area(turf.polygon(polygonGeometryToGeoJSONCoordinates(geometry)));
}

const _testSubjects = {
  isOverlapping,
  getElevation,
  polygonGeometryToGeoJSONCoordinates,
  geoJSONCoordinatesToPolygonGeometry,
  getArea,
};

export { _testSubjects };
