import * as vuetify from 'vuetify';
import { en, fr } from 'vuetify/locale';
import { customIcons } from '@/components/icons/customIcons';
import { mdi } from 'vuetify/iconsets/mdi';
import { powerDisTheme } from '@efficacity/components-library-vuejs3';

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

export function createVuetify(locale: string) {
  const buildLocaleSettings: () => any = () => {
    if (locale === 'en') {
      return { locale: 'en', fallback: 'en', messages: { en } };
    } else if (locale === 'fr' || locale === 'test') {
      return { locale: 'fr', fallback: 'en', messages: { fr } };
    } else {
      throw new Error('unknown locale');
    }
  };

  return vuetify.createVuetify({
    locale: buildLocaleSettings(),
    theme: {
      defaultTheme: 'powerDisTheme',
      themes: {
        powerDisTheme: {
          variables: { ...powerDisTheme.variables },
          colors: {
            ...powerDisTheme.colors,
            'stepper-success': '#64bb6a',
            'stepper-locked': '#64bb6a',
            'stepper-error': '#e95c50',
            'on-stepper-error': '#e95c50',
            'stepper-action-required': '#5c75a4',
            'stepper-running': '#f5a623',
            'on-stepper-running': '#ffffff',
            'stepper-canceled': '#f5a623',
            'on-stepper-canceled': '#ffffff',
            'stepper-future': '#7f7f7f',
            hover: '#d6d6db',
            selected: '#bcc6da',
            hoverAndSelected: '#a1aec9',
          },
        },
      },
    },
    icons: {
      defaultSet: 'mdi',
      sets: {
        mdi,
        custom: customIcons,
      },
    },
  });
}
