import { AxiosError } from 'axios';
import { geoJSONCoordinatesToPolygonGeometry } from '@/util/geography';
import { type DefaultApi, type StudyStudyIdGeojsonGet200ResponseOneOf } from '../api-client';
import type {
  ThermalZoneApi,
  ThermalZone,
  ThermalZoneProperties,
} from './thermal-zone.interface';
import { ForbiddenAccessError, InvalidInputError, InvalidResponseError } from '../errors';

const rawThermalZoneProperties: Array<string> = [
  'name',
  'building_id',
  'building_name',
  'altitude',
  'height',
  'mean_floor_height',
  'floor_count',
  'gross_floor_area',
  'usable_floor_area_ratio',
  'usable_floor_area',
  'usage_zone_code',
  'performance',
  'year',
  'infiltration_rate',
  'ventilation_system',
  'ExteriorFloor_U_value',
  'ExteriorFloor_inertia',
  'ExteriorFloor_insulation',
  'ExteriorRoof_U_value',
  'ExteriorRoof_inertia',
  'ExteriorRoof_insulation',
  'ExteriorRoof_window_type',
  'ExteriorRoof_window_U_value',
  'ExteriorRoof_window_solar_absorption',
  'ExteriorRoof_window_transmission_factor',
  'ExteriorRoof_window_share',
  'ExteriorWall_U_value',
  'ExteriorWall_inertia',
  'ExteriorWall_insulation',
  'ExteriorWall_window_type',
  'ExteriorWall_window_U_value',
  'ExteriorWall_window_share',
  'ExteriorWall_window_solar_absorption',
  'ExteriorWall_window_transmission_factor',
  'blind_control_mode',
  'closed_blind_ratio',
  'closing_deltaT',
  'open_blind_ratio',
  'temperature_blind_closed',
];

function validateId(id: string): void {
  const regex = /^[a-zA-Z]_\d+$/;
  if (!regex.test(id)) {
    throw new InvalidResponseError();
  }
}

function validateProperties(properties: any): ThermalZoneProperties {
  validateId(properties.building_id);
  validateId(properties.id);
  const validProperties: any = {};
  rawThermalZoneProperties.forEach((property) => {
    if (properties[property] !== undefined) {
      validProperties[property] = properties[property];
    } else {
      throw new InvalidResponseError();
    }

    const exteriorWallWindowShare = properties.ExteriorWall_window_share;
    delete validProperties.ExteriorWall_window_share;

    validProperties.ExteriorWall_window_share_N = exteriorWallWindowShare.N;
    validProperties.ExteriorWall_window_share_NE = exteriorWallWindowShare.NE;
    validProperties.ExteriorWall_window_share_NW = exteriorWallWindowShare.NW;
    validProperties.ExteriorWall_window_share_S = exteriorWallWindowShare.S;
    validProperties.ExteriorWall_window_share_SE = exteriorWallWindowShare.SE;
    validProperties.ExteriorWall_window_share_SW = exteriorWallWindowShare.SW;
    validProperties.ExteriorWall_window_share_E = exteriorWallWindowShare.E;
    validProperties.ExteriorWall_window_share_W = exteriorWallWindowShare.W;

    if (properties.performance === 'Indéfinie') {
      validProperties.performance = 'UNDEFINED';
    }
  });
  return validProperties;
}

class ThermalZoneApiFacade implements ThermalZoneApi {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private apiClient: DefaultApi) {}

  async getStudyThermalZones(studyId: number): Promise<Array<ThermalZone>> {
    try {
      const response = await this.apiClient.studyStudyIdGeojsonGet(studyId, true);

      if (response.data.status === false) {
        throw new InvalidInputError();
      }

      const rawThermalZones = (response.data as StudyStudyIdGeojsonGet200ResponseOneOf).data;

      if (rawThermalZones === undefined) {
        throw new InvalidResponseError();
      }

      const features = rawThermalZones.thermal_zone.features.map((feature) => ({
        id: feature.properties.id,
        properties: validateProperties(feature.properties),
        geometry: geoJSONCoordinatesToPolygonGeometry(
          feature.geometry.coordinates as Array<Array<[number, number]>>,
        ),
      }));
      return features;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 403) {
        throw new ForbiddenAccessError();
      } else {
        throw new InvalidResponseError();
      }
    }
  }
}

const _testSubjects = {
  validateProperties,
  validateId,
};

export { _testSubjects, ThermalZoneApiFacade };
