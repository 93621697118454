<script setup lang="ts">
import { ref } from 'vue';
import { allLocales, getLocale, switchLocaleAndReload } from '@/plugins/i18n';

const langItems = allLocales.map((locale) => ({ title: locale, value: locale }));

const locale = ref(getLocale());

function changeLanguage() {
  switchLocaleAndReload(locale.value);
}
</script>

<template>
  <v-select
    v-model="locale"
    :items="langItems"
    hide-details
    flat
    density="compact"
    variant="outlined"
    class="lang-selector"
    @update:model-value="changeLanguage"
  />
</template>

<style lang="scss">
#app {
  .lang-selector {
    display: inline-block;
    width: auto;
    margin-bottom: 16px;
  }
}
</style>
