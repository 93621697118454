<script lang="ts" setup>
import { HeaderAndMenu } from '@efficacity/components-library-vuejs3';
import '@efficacity/components-library-vuejs3/style.css';
import { isRtl, useI18n } from '@/plugins/i18n';
import { logout } from '@/plugins/keycloak';
import LangSelector from '@/components/LangSelector.vue';
import { useApi } from '@/api';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const { currentRoute } = useRouter();

const login = ref<string>();

async function getLogin() {
  login.value = (await useApi().currentUser).login;
}

getLogin();
</script>

<template>
  <v-app full-height>
    <v-locale-provider :rtl="isRtl()">
      <v-layout>
        <header-and-menu>
          <template #title>
            <router-link to="/">
              <VImg src="/PowerDIS_logo.svg" height="40" width="150" />
            </router-link>
          </template>

          <template #actions>
            <div>{{ login }}</div>
            <v-tooltip :text="t('logout')">
              <template #activator="{ props }">
                <button v-bind="props" @click="logout"><v-icon>mdi-logout</v-icon></button>
              </template>
            </v-tooltip>
          </template>

          <template #menuList>
            <ul>
              <li>
                <router-link to="/">{{ t('menu.home') }}</router-link>
              </li>
            </ul>
          </template>

          <template #menuInfos>
            <lang-selector />
            <p>{{ t('menu.contactUsInCaseOfIssue') }}</p>
          </template>
        </header-and-menu>

        <v-main scrollable>
          <!-- :key forces re-rendering the view component when the route changes (even if the
          target component doesn't) -->
          <router-view :key="currentRoute.fullPath" />
        </v-main>
      </v-layout>
    </v-locale-provider>
  </v-app>
</template>

<style lang="scss">
html {
  overflow: hidden;
  font-size: 16px;
  font-family: 'Lato', Arial, sans-serif;
}

.v-tooltip {
  white-space: pre-line;
}

#app {
  .v-main {
    @include backgroundAndColor('background');
  }

  // to delete when starting a new project, only for styling the boilerplate demonstration
  h1,
  h2,
  h3 {
    margin-block-end: 16px;
  }

  .warning-title {
    font-size: 40px;
    @include color('warning');
  }

  section {
    margin: 50px 0;
  }

  .styled-list {
    margin-block-start: 20px;
    padding-inline-start: 20px;
    list-style-type: disc;

    & > li {
      margin-block-end: 10px;

      ul {
        padding-inline-start: 20px;
      }
    }
  }

  .vuetify-theme-color-sample {
    display: inline-block;
  }

  .styled-code {
    display: inline-block;
    margin: 10px 0;
    padding: 6px;
    @include background('border');
  }

  .enforce-min-page-width {
    min-width: 1250px;
  }

  // #region Leaflet
  // controls buttons & corner infos
  .leaflet-top {
    margin-top: 10px;
  }

  .leaflet-bottom.leaflet-left {
    margin-bottom: 10px;
  }
  // #endregion
}
</style>
