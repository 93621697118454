import * as vueRouter from 'vue-router';

const NewProjectView = () =>
  import(
    /* webpackChunkName: "new-project-view" */ '@/views/dashboard/new-project/NewProjectView.vue'
  );
const ProjectsListView = () =>
  import(
    /* webpackChunkName: "projects-list-view" */ '@/views/dashboard/projects-list/ProjectsListView.vue'
  );
const ProjectDetailView = () =>
  import(
    /* webpackChunkName: "project-detail-view" */ '@/views/dashboard/project-detail/ProjectDetailView.vue'
  );
const EditProjectView = () =>
  import(
    /* webpackChunkName: "edit-project-view" */ '@/views/dashboard/edit-project/EditProjectView.vue'
  );
const DuplicateProjectView = () =>
  import(
    /* webpackChunkName: "duplicate-project-view" */ '@/views/dashboard/duplicate-project/DuplicateProjectView.vue'
  );
const NewStudyView = () =>
  import(
    /* webpackChunkName: "new-study-view" */ '@/views/dashboard/new-study/NewStudyView.vue'
  );
const DuplicateStudyView = () =>
  import(
    /* webpackChunkName: "duplicate-study-view" */ '@/views/dashboard/duplicate-study/DuplicateStudyView.vue'
  );
const EditStudyView = () =>
  import(
    /* webpackChunkName: "edit-study-view" */ '@/views/dashboard/edit-study/EditStudyView.vue'
  );
const StudySystemsView = () =>
  import(
    /* webpackChunkName: "study-systems-view" */ '@/views/study-systems/StudySystemsView.vue'
  );
const ResultsConsumptionsView = () =>
  import(
    /* webpackChunkName: "results-consumptions-view" */ '@/views/results-consumptions/ResultsConsumptionsView.vue'
  );
const ResultsDemandsView = () =>
  import(
    /* webpackChunkName: "results-demands-view" */ '@/views/results-demands/ResultsDemandsView.vue'
  );
const ErrorView = () => import(/* webpackChunkName: "error-view" */ '@/views/ErrorView.vue');
const BuildingsModellingView = () =>
  import(
    /* webpackChunkName: "buildings-modelling-view" */ '@/views/buildings-modelling/BuildingsModellingView.vue'
  );

export function createRouter() {
  return vueRouter.createRouter({
    history: vueRouter.createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: 'projects-list',
        component: ProjectsListView,
      },
      {
        path: '/new-project',
        name: 'new-project',
        component: NewProjectView,
      },
      {
        path: '/project/:projectid',
        name: 'project-detail',
        component: ProjectDetailView,
      },
      {
        path: '/project/:projectid/new-study',
        name: 'new-study',
        component: NewStudyView,
      },
      {
        path: '/project/:projectid/duplicate',
        name: 'duplicate-project',
        component: DuplicateProjectView,
      },
      {
        path: '/project/:projectid/edit',
        name: 'edit-project',
        component: EditProjectView,
      },
      {
        path: '/study/:studyid/duplicate',
        name: 'duplicate-study',
        component: DuplicateStudyView,
      },
      {
        path: '/study/:studyid/edit',
        name: 'edit-study',
        component: EditStudyView,
      },
      {
        path: '/study/:studyId(\\d+)/buildings-modelling',
        name: 'buildings-modelling',
        component: BuildingsModellingView,
        props: (route) => ({ studyId: parseInt(route.params.studyId as string, 10) }),
      },
      {
        path: '/study/:studyid/study-systems',
        name: 'study-systems',
        component: StudySystemsView,
      },
      {
        path: '/study/:studyid/results-demands',
        name: 'results-demands',
        component: ResultsDemandsView,
      },
      {
        path: '/study/:studyid/results-consumptions',
        name: 'results-consumptions',
        component: ResultsConsumptionsView,
      },
      {
        path: '/forbidden',
        name: 'forbidden',
        component: ErrorView,
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'page-not-found',
        component: ErrorView,
      },
      {
        path: '/error',
        name: 'error',
        component: ErrorView,
      },
    ],
  });
}
