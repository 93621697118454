import Keycloak from 'keycloak-js';

export class TokenError extends Error {}

const keycloak = new Keycloak({
  url: import.meta.env.VITE_KEYCLOAK_URL,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
});

/**
 * Initialize the Keycloak adapter.
 *
 * - If an authorization code was received in the URL (end of the redirect-based authentication
 *   flow),
 *     - use it to request the access, refresh and ID tokens,
 *     - wipe the hash in the URL,
 *     - auto-refresh the access token when it expires.
 * - Otherwise, initiate a redirect-based authentication flow = redirect to Keycloak's login
 *   page.
 *
 * Upon return it is guaranteed that `keycloak` is initialized with a valid token.
 */
async function initialize(): Promise<void> {
  keycloak.onTokenExpired = () => {
    keycloak.updateToken(-1);
  };

  await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false });
}

// Initiate a redirect-based logout flow
function logout(): void {
  keycloak.logout();
}

type TokenData = {
  email: string;
  firstName: string;
  lastName: string;
  login: string;
  clientRoles: string[];
};

/**
 * Return the base64-encoded bearer token for API calls.
 *
 * First ensure the access token is still valid for long enough, so that it is not expired once the
 * backend re-checks it. Refresh if needed. Throw an exception if there is no valid token.
 */
async function getToken(): Promise<string> {
  await keycloak.updateToken(5);
  if (keycloak.token === undefined) {
    throw new TokenError('No token. Have you called initialize() yet?');
  }
  return keycloak.token;
}

/**
 * Extract the useful data from the ID token returned by Keycloak.
 * First ensure the token is still valid, see `getToken()`.
 */
async function getTokenData(): Promise<TokenData> {
  await getToken();
  const token = keycloak.idTokenParsed!;

  if (!token.email) {
    throw new TokenError('Email is missing in token data.');
  }
  if (!token.given_name) {
    throw new TokenError('Given name is missing in token data.');
  }
  if (!token.family_name) {
    throw new TokenError('Family name is missing in token data.');
  }
  if (!token.preferred_username) {
    throw new TokenError('Preferred username is missing in token data.');
  }
  return {
    email: token.email,
    firstName: token.given_name,
    lastName: token.family_name,
    login: token.preferred_username,
    clientRoles: token.resource_access?.[keycloak.clientId!]?.roles ?? [],
  };
}

export { initialize, logout, getToken, getTokenData, type TokenData };
