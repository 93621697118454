/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApp } from 'vue';
import { createPinia } from 'pinia';

import 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import * as i18n from '@/plugins/i18n';
import * as keycloak from '@/plugins/keycloak';
import { createVuetify } from '@/plugins/vuetify';
import { Toast, toastOptions } from '@/plugins/vue-toastification';
import { createRouter } from '@/router';
import { isUsingMocks } from '@/api';
import App from '@/views/app/App.vue';

const app = createApp(App);

if (!isUsingMocks()) {
  await keycloak.initialize();
}

app.use(await i18n.initialize());
app.use(createPinia());
app.use(createVuetify(i18n.getLocale()));
app.use(Toast, toastOptions);
app.use(createRouter());

app.mount('#app');
