import { InvalidResponseError, ForbiddenAccessError, NotFoundError } from './errors';

// #region Parsing utilities

// They should throw `ParseError`s but not anticipate on the consequence, i.e. often **but not
// always** throw a higher-level `InvalidResponseError`. Let the calling function decide of that.

export class ParseError extends Error {}

export function safeParseDate(input: string): Date {
  const d = new Date(input);
  if (Number.isNaN(d.valueOf())) {
    throw new ParseError(`invalid date string: '${input}'`);
  }
  return d;
}

export function safeParseFloat(input: string): number {
  const n = parseFloat(input);
  if (Number.isNaN(n)) {
    throw new ParseError(`invalid float string: '${input}'`);
  }
  return n;
}

export function convertIfParseError(error: any): any {
  if (error instanceof ParseError) {
    return new InvalidResponseError(
      `received an invalid response from the API (cannot parse: ${error.message})`,
    );
  } else {
    return error;
  }
}

// #endregion

// #region Facades unit testing utilities

/**
 * Fail if awaiting for `fn()` did not throw an `InvalidResponseError`.
 *
 * It is a workaround for:
 *     expect(expression).to.be.eventually.rejectedWith('error message')
 *
 * vitest does not seem to provide a full chai `expect`.
 */
export async function expectThrowsInvalidResponseError<T>(fn: () => Promise<T>) {
  try {
    await fn();
    assert.fail(`did not throw an error`);
  } catch (error) {
    if (!(error instanceof InvalidResponseError)) {
      throw error;
    }
  }
}

export async function expectThrowsForbiddenAccessError<T>(fn: () => Promise<T>) {
  try {
    await fn();
    assert.fail(`did not throw an error`);
  } catch (error) {
    if (!(error instanceof ForbiddenAccessError)) {
      throw error;
    }
  }
}

export async function expectThrowsNotFoundError<T>(fn: () => Promise<T>) {
  try {
    await fn();
    assert.fail(`did not throw an error`);
  } catch (error) {
    if (!(error instanceof NotFoundError)) {
      throw error;
    }
  }
}

// #endregion
